import React from 'react'
import SbEditable from 'storyblok-react'

const Video = ({ blok, style }) => {
  const {
    autoplay,
    loopPlayback,
    title,
    videoAsset: { filename },
  } = blok

  return (
    <SbEditable content={blok}>
      <video
        autoPlay={autoplay}
        loop={loopPlayback}
        muted
        playsInline
        src={filename}
        style={{
          ...style,
        }}
        title={title || null}
      />
    </SbEditable>
  )
}

export default Video
