import React from 'react'
import ReactPlayer from 'react-player'
import convertUrlToHttps from '@helpers/convert-url-to-https'

const Video = (props) => {
  const url = props.url
  return (
    <ReactPlayer
      url={convertUrlToHttps(url)}
      playing={false}
      controls={true}
      width="100%"
      height="100%"
    />
  )
}

export default Video
